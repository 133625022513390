// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import '../stylesheets/core.scss'

const feather = require('feather-icons');

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import '../module/core/bootstrap.bundle.min'
import '../module/core/tiny-slider'
// import '../module/core/feather.min'
import '../module/core/plugins.init'
import '../module/core/app'

function toggleMenu() {
  document.getElementById('isToggle').classList.toggle('open');
  var isOpen = document.getElementById('navigation')
  if (isOpen.style.display === "block") {
      isOpen.style.display = "none";
  } else {
      isOpen.style.display = "block";
  }
};

// ----------- header_banner -----------
const setCookie = (cname, cvalue, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  let expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

const getCookie = (cname) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
};

// const headerBanner = () => {
//   const name = 'header_banner_shopify';
//   const alertNode = document.querySelector('.header-banner');
//   console.log(getCookie(name));
//   if (!getCookie(name)) {
//     alertNode.style.display = 'block';
//   }
//   alertNode.addEventListener('closed.bs.alert', () => {
//     setCookie(name, Date.now());
//   });
// };
// -------------------------------------

const main = () => {
  feather.replace();

  const toggle = document.querySelector('#isToggle');
  if (toggle) {
    toggle.addEventListener('click', () => {
      toggleMenu();
    });
  }
};

document.addEventListener("DOMContentLoaded", () => {
  main();
  // headerBanner();
});
